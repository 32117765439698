let submittedForm = null;
function shouldResumeField(field) {
    return !!field.id && field.value !== field.defaultValue && field.form !== submittedForm;
}
export function persistResumableFields(id, options) {
    var _a, _b;
    const selector = (_a = options === null || options === void 0 ? void 0 : options.selector) !== null && _a !== void 0 ? _a : '.js-session-resumable';
    const keyPrefix = (_b = options === null || options === void 0 ? void 0 : options.keyPrefix) !== null && _b !== void 0 ? _b : 'session-resume:';
    const key = `${keyPrefix}${id}`;
    const resumables = [];
    for (const el of document.querySelectorAll(selector)) {
        if (el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement) {
            resumables.push(el);
        }
    }
    let fields = resumables.filter(field => shouldResumeField(field)).map(field => [field.id, field.value]);
    if (fields.length) {
        try {
            const previouslyStoredFieldsJson = sessionStorage.getItem(key);
            if (previouslyStoredFieldsJson !== null) {
                const previouslyStoredFields = JSON.parse(previouslyStoredFieldsJson);
                const fieldsNotReplaced = previouslyStoredFields.filter(function (oldField) {
                    return !fields.some(field => field[0] === oldField[0]);
                });
                fields = fields.concat(fieldsNotReplaced);
            }
            sessionStorage.setItem(key, JSON.stringify(fields));
        }
        catch (_c) {
        }
    }
}
export function restoreResumableFields(id, options) {
    var _a;
    const keyPrefix = (_a = options === null || options === void 0 ? void 0 : options.keyPrefix) !== null && _a !== void 0 ? _a : 'session-resume:';
    const key = `${keyPrefix}${id}`;
    let fields;
    try {
        fields = sessionStorage.getItem(key);
    }
    catch (_b) {
    }
    if (!fields)
        return;
    const changedFields = [];
    const storedFieldsNotFound = [];
    for (const [fieldId, value] of JSON.parse(fields)) {
        const resumeEvent = new CustomEvent('session:resume', {
            bubbles: true,
            cancelable: true,
            detail: { targetId: fieldId, targetValue: value }
        });
        if (document.dispatchEvent(resumeEvent)) {
            const field = document.getElementById(fieldId);
            if (field && (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement)) {
                if (field.value === field.defaultValue) {
                    field.value = value;
                    changedFields.push(field);
                }
            }
            else {
                storedFieldsNotFound.push([fieldId, value]);
            }
        }
    }
    if (storedFieldsNotFound.length === 0) {
        try {
            sessionStorage.removeItem(key);
        }
        catch (_c) {
        }
    }
    else {
        sessionStorage.setItem(key, JSON.stringify(storedFieldsNotFound));
    }
    setTimeout(function () {
        for (const el of changedFields) {
            el.dispatchEvent(new CustomEvent('change', { bubbles: true, cancelable: true }));
        }
    }, 0);
}
export function setForm(event) {
    submittedForm = event.target;
    setTimeout(function () {
        if (event.defaultPrevented) {
            submittedForm = null;
        }
    }, 0);
}
